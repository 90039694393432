import {userLogout} from "@/services/UserService";

interface AuthStoreState {
    userToken: string;
    loggedInUser: any;
    isLoggedIn: boolean;
}

export default {
    namespaced: true,
    state(): AuthStoreState {
        return {
            userToken: '',
            loggedInUser: {},
            isLoggedIn: false
        };
    },
    actions: {
        async logout({commit, state}: { commit: Function; state: AuthStoreState }) {
            if (state.userToken) {
                await userLogout();
            }
            commit('logout');
        },
        setToken({ commit }: { commit: Function }, token: string) {
            commit('setToken', token);
        },
        setLoggedInUser({ commit }: { commit: Function }, userObject: any) {
            commit('setLoggedInUser', userObject);
        },
    },
    mutations: {
        setToken(state: any, token: string) {
            state.userToken = token;
            if (state.userToken) {
                state.isLoggedIn = true;
            }
        },
        setLoggedInUser(state: any, userObject: any) {
            state.loggedInUser = userObject;
        },
        logout(state: any) {
            state.loggedInUser = {};
            state.userToken = '';
            state.isLoggedIn = false;
        }
    },
    getters: {
        token: (state: any) => {
            return state.userToken;
        },
        loggedInUser: (state: any) => {
            return state.loggedInUser;
        },
        isLoggedIn: (state: any) => {
            return state.isLoggedIn;
        },
    }
}
