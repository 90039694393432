import { createStore }from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import app from '@/store/AppStore';
import auth from '@/store/AuthStore';

export const store = createStore({
    modules: {
        app,
        auth
    },
    plugins: [createPersistedState({
        paths: [
            'auth', 'app.selectedAppInstanceId', 'app.language'
        ]
    })]
});
