
import {IonApp, IonRouterOutlet} from '@ionic/vue';
import { defineComponent, ref, watch, onBeforeMount, computed } from 'vue';
import BaseLayout from '@/components/BaseLayout.vue';
import { useRoute } from 'vue-router';
import {useStore} from "vuex";
import {setEnumValues} from "@/utils/EnumDefaults";

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet,
        BaseLayout
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const route = useRoute();
        const showBaseLayout = ref(true);
        const noBaseLayoutRoutes = ['login', 'resetPassword', 'activate'];

        const setShowBaseLayout = () => {
            showBaseLayout.value = !noBaseLayoutRoutes.includes(route.name as string);
        };
        
        onBeforeMount(async () => {
            setShowBaseLayout();
            if (showBaseLayout.value) {
                setEnumValues(language.value);
            }
        });
        
        watch(route, async () => {
            setShowBaseLayout();
        });
        
        return {
            showBaseLayout,
            direction: computed(() => language.value.direction),
            directionClass: computed(() => language.value.direction + ' md ion-page hydrated'),
            pageTitle: computed(() => store.getters['app/getPageTitle'])
        }
    }
});
