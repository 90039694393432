

import {IonIcon} from '@ionic/vue';
import {computed} from 'vue'
import {chevronBackOutline, chevronForwardOutline} from "ionicons/icons";
import {useStore} from "vuex";

export default {
    name: 'DirectionIndicator',
    props: {
        isLtrForward: {
            type: Boolean
        }
    },
    components: {
        IonIcon
    },
    setup(props: any) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        return {
            directionIcon: computed(() => {
                if ((language.value.direction === 'rtl' && props.isLtrForward) || (language.value.direction === 'ltr' && !props.isLtrForward)) {
                    return chevronBackOutline;
                }
                return chevronForwardOutline;
            })
        }
    }
}
