export enum JourneySetTypeId {
    jf1 = 'b8bd72aa-95c8-486b-b370-7c9ed1540d67',
    jf2 = '54144a23-092c-48c5-9913-4b6c186178e8',
    jf3 = 'adc3d1c5-19ed-4265-8569-2f8523762bc2',
    jf4 = 'd535b1ad-fa96-45f1-853c-92ed093aeb91',
    jf5 = '896695fd-7994-4a63-9000-34d24174fcb2',
    jf6 = '6dc9cbae-35f7-4958-a7e2-a028e89382aa'
}

export const JOURNEY_SET_TYPES = new Map<string, string>([
    [JourneySetTypeId.jf1, 'set in setEnumValues()'],
    [JourneySetTypeId.jf2, 'set in setEnumValues()'],
    [JourneySetTypeId.jf3, 'set in setEnumValues()'],
    [JourneySetTypeId.jf4, 'set in setEnumValues()'],
    [JourneySetTypeId.jf5, 'set in setEnumValues()'],
    [JourneySetTypeId.jf6, 'set in setEnumValues()']
]);