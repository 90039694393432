<template>
    <ion-header>
        <ion-toolbar color="primary" mode="ios">
            <ion-buttons slot="start" v-if="showBackIcon">
                <DirectionIndicator class="back-image" :is-ltr-forward="false" @click="goBack()"/>
                <div v-if="notHome" class="icon-container home-icon" @click="goHome"><ion-icon :icon="homeSharp"></ion-icon></div>
            </ion-buttons>
            <ion-buttons slot="end">
                <ion-menu-button color="light"><ion-icon v-if="isMenuOpen" class="close-icon" :icon="closeSharp"/></ion-menu-button>
            </ion-buttons>
            <ion-title v-if="pageTitle.length > 0">{{ pageTitle }}</ion-title>
        </ion-toolbar>
        
    </ion-header>
    
    <ion-menu
        content-id="main-content"
        class="custom-menu"
        :side="dynamicSide"
        swipe-gesture="false"
        @ion-will-open="toggleMenu(true)"
        @ion-will-close="toggleMenu(false)">
        <ion-content>
            
            <ion-list id="inbox-list">
                <ion-menu-toggle auto-hide="true" v-if="canManagePartners">
                    <ion-item router-direction="forward" lines="none" class="hydrated" @click="goToPartners">
                        <ion-icon :icon="contractOutline"></ion-icon>
                        <ion-label>{{ language.menu.managePartners }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle auto-hide="true" v-if="canManagePublished">
                    <ion-item router-direction="forward" lines="none" class="hydrated" @click="goToPublished">
                        <ion-icon :icon="phonePortraitSharp"></ion-icon>
                        <ion-label>{{ language.menu.managePublished }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle auto-hide="true" v-if="canManageUsers">
                    <ion-item router-direction="forward" lines="none" class="hydrated" @click="goToUsers">
                        <ion-icon :icon="peopleSharp"></ion-icon>
                        <ion-label>{{ language.menu.manageUsers }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle auto-hide="true" v-if="canManageAppInstances">
                    <ion-item router-direction="forward" lines="none" class="hydrated" @click="goToAppInstances">
                        <ion-icon :icon="globeOutline"></ion-icon>
                        <ion-label>{{ language.menu.manageAppInstances }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle auto-hide="true" v-if="canManageImages">
                    <ion-item router-direction="forward" lines="none" class="hydrated" @click="goToImages">
                        <ion-icon :icon="imagesOutline"></ion-icon>
                        <ion-label>{{ language.menu.manageImages }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle auto-hide="true" v-if="canManageLanguages">
                    <ion-item router-direction="forward" lines="none" class="hydrated" @click="goToLanguages">
                        <ion-icon :icon="languageOutline"></ion-icon>
                        <ion-label>{{ language.menu.manageLanguages }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle auto-hide="true">
                    <ion-item router-direction="forward" lines="none" class="hydrated" @click="goToMyAccount">
                        <ion-icon :icon="personSharp"></ion-icon>
                        <ion-label>{{ language.menu.myAccount }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle auto-hide="true" @click="logout">
                    <ion-item lines="none" class="hydrated" >
                        <ion-icon :icon="logOutOutline"></ion-icon>
                        <ion-label>{{ language.menu.logout }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
            </ion-list>
        </ion-content>
    </ion-menu>
</template>

<script lang="ts">
import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonMenuButton,
    menuController, 
    IonToolbar, 
    IonButtons,
    IonHeader,
    IonTitle
} from '@ionic/vue';
import { ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
    homeSharp,
    languageOutline,
    globeOutline,
    phonePortraitSharp,
    contractOutline,
    personSharp,
    peopleSharp,
    imagesOutline,
    closeSharp,
    logOutOutline
} from 'ionicons/icons';
import {useStore} from "vuex";
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default {
    name: 'BaseLayout',
    props: {
        pageTitle: {
            type: String,
            default: ""
        }  
    },
    components: {
        DirectionIndicator,
        IonContent,
        IonIcon,
        IonItem,
        IonLabel,
        IonList,
        IonMenu,
        IonMenuToggle,
        IonMenuButton,
        IonToolbar,
        IonButtons,
        IonHeader,
        IonTitle
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const selectedIndex = ref(0);
        const language = computed(() => store.getters['app/language']);
        const dynamicSide = computed(() => language.value.direction === 'rtl' ? 'start' : 'end');
        const isMenuOpen = ref(false);
        const showBackIcon = ref(route.name !== 'home');
        const currentUser = ref(store.getters['auth/loggedInUser']);
        
        watch(route, (currentRoute) => {
            showBackIcon.value = currentRoute.name !== 'home';
        });
       
        function toggleMenu(value: boolean) {
            isMenuOpen.value = value;
        }

        async function goBack() {
            await menuController.close();
            if (router.currentRoute.value.meta.goBackTo) {
                await router.replace({ name: router.currentRoute.value.meta.goBackTo as string, params: router.currentRoute.value.params });
            } else {
                await router.back();
            }
        }
        
        function goToAppInstances() {
            router.push({name: 'appInstances'});
        }

        function goToUsers() {
            router.push({name: 'users'});
        }

        function goToPartners() {
            router.push({name: 'partners'});
        }
        
        function goToPublished() {
            router.push({ name: 'publishedJourneySetGroups' });
        }
        
        function goToImages() {
            router.push({ name: 'imageGroups'});
        }

        function goToLanguages() {
            router.push({ name: 'languages'});
        }

        async function goHome() {
            await menuController.close();
            router.push({ name: 'home'});
        }
        
        function goToMyAccount() {
            router.push({ name: 'myAccount' });
        }
        
        async function logout() {
            await menuController.close();
            await store.dispatch('auth/logout');
            router.push({ name: 'login'});
        }
        
        return {
            selectedIndex,
            globeOutline,
            homeSharp,
            phonePortraitSharp,
            contractOutline,
            personSharp,
            peopleSharp,
            imagesOutline,
            languageOutline,
            logOutOutline,
            closeSharp,
            dynamicSide,
            toggleMenu,
            isMenuOpen,
            goBack,
            goToAppInstances,
            goToUsers,
            goToImages,
            goHome,
            goToMyAccount,
            goToPublished,
            goToLanguages,
            goToPartners,
            showBackIcon,
            logout,
            language: computed(() => store.getters['app/language']),
            canManagePublished: computed(() => currentUser.value.permissions?.includes("ManagePublished")),
            canManageUsers: computed(() => currentUser.value.permissions?.includes("ManageUsers")),
            canManageAppInstances: computed(() => currentUser.value.permissions?.includes("ManageAppInstances")),
            canManageImages: computed(() => currentUser.value.permissions?.includes("ManageImages")),
            canManageLanguages: computed(() => currentUser.value.permissions?.includes("ManageLanguages")),
            canManagePartners: computed(() => currentUser.value.permissions?.includes("ManagePartners")),
            notHome: computed(() => router.currentRoute.value.name !== 'home')
        }
    }
}
</script>

<style scoped>

ion-title {
    padding: 0 80px;
    font-size: 26px;
}

ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

.ltr ion-menu-button {
    margin-right: 5px;
}

.rtl ion-menu-button {
    margin-left: 5px;
}

ion-menu-button::part(icon) {
    font-size: 25px;
}

.custom-menu{
    top: 56px;
    padding: 0;
    --max-width: 350px;
    --width: 350px;
}

.custom-menu ion-item {
    font-size: 18px;
    border-bottom: 1px solid var(--color-step-750);
}

.custom-menu ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.14);
    --color: var(--ion-color-primary);
}

.custom-menu ion-label {
    font-weight: normal;
    color: var(--color-step-200);
}

.custom-menu ion-icon {
    color: var(--ion-color-primary);
}

.ltr .custom-menu ion-icon {
    padding-right: 15px;
}
.rtl .custom-menu ion-icon {
    padding-left: 15px;
}

.custom-menu ion-list {
    padding: 0;
}

.ltr .back-image {
    margin-left: 12px;
}

.rtl .back-image {
    margin-right: 12px;
}

.home-icon {
    font-size: 20px;
    padding: 0 10px;
}
</style>