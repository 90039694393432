import {IMAGE_GROUP_TYPES, ImageGroupId} from "@/models/ImageGroupTypes";
import {JOURNEY_SET_TYPES, JourneySetTypeId} from "@/models/JourneySetTypes";

export const setEnumValues = (language: any) => {
    IMAGE_GROUP_TYPES.set(ImageGroupId.JourneySetIcon, language.journeySetIcons);
    IMAGE_GROUP_TYPES.set(ImageGroupId.JourneyImages, language.journeyImages);
    JOURNEY_SET_TYPES.set(JourneySetTypeId.jf1, language.quadrants.jf1.name);
    JOURNEY_SET_TYPES.set(JourneySetTypeId.jf2, language.quadrants.jf2.name);
    JOURNEY_SET_TYPES.set(JourneySetTypeId.jf3, language.quadrants.jf3.name);
    JOURNEY_SET_TYPES.set(JourneySetTypeId.jf4, language.quadrants.jf4.name);
    JOURNEY_SET_TYPES.set(JourneySetTypeId.jf5, language.quadrants.jf5.name);
    JOURNEY_SET_TYPES.set(JourneySetTypeId.jf6, language.quadrants.jf6.name);

}