import {getAppInstance} from "@/services/AppInstanceService";
import {setEnumValues} from "@/utils/EnumDefaults";

interface AppStoreState {
    pageTitle: string;
    selectedAppInstanceId: string;
    selectedAppInstanceSuspended: boolean;
    language: any;
}

export default {
    namespaced: true,
    state(): AppStoreState {
        return {
            pageTitle: "",
            selectedAppInstanceId: "",
            selectedAppInstanceSuspended: false,
            language: {}
        };
    },
    mutations: {
        setPageTitle(state: any, title: string) {
            state.pageTitle = title;
        },
        setSelectedAppInstanceId(state: any, appInstanceId: string) {
            state.selectedAppInstanceId = appInstanceId;
        },
        setSelectedAppInstanceSuspend(state: any, suspended: boolean) {
          state.selectedAppInstanceSuspended = suspended;  
        },
        setLanguage(state: any, language: {}) {
            state.language = language;
        }
    },
    actions: {
        async setPageTitle({ commit }: { commit: Function }, title: string) {
            commit('setPageTitle', title);
        },
        async setSelectedAppInstanceId(context: any, appInstanceId: string) {
            const appInstance = await getAppInstance(appInstanceId);
            const language = JSON.parse(decodeURIComponent(escape(atob(appInstance.languageFile.split(",")[1]))));
            await context.dispatch('setLanguage', language);
            setEnumValues(language);
            context.commit('setSelectedAppInstanceId', appInstanceId);
            context.commit('setSelectedAppInstanceSuspend', appInstance.suspended);
        },
        async setLanguage({ commit }: { commit: Function }, language: any) {
            commit('setLanguage', language);
        }
    },
    getters: {
        getPageTitle: (state: AppStoreState) => {
            return state.pageTitle;
        },
        selectedAppInstanceId: (state: AppStoreState) => {
            return state.selectedAppInstanceId;
        },
        language: (state: AppStoreState) => {
            return state.language;
        },
        getSuspendedState: (state: AppStoreState) => {
            return state.selectedAppInstanceSuspended;
        }
    }
}
