import { store } from '@/store/Store';
import router from '@/router';
import { presentNativeAlert } from '@/services/Notify';

function headers(isFileUpload= false) {
    const allHeaders: any = {
        Accept: 'application/json, */*',
    };

    if (!isFileUpload) {
        allHeaders['Content-Type'] = 'application/json';
    }
    
    const token = store.getters['auth/token'];
    if (token !== '') {
        allHeaders.Authorization = 'Bearer ' + token;
    }
    return allHeaders;
}

async function handleBadResponse(response: Response) {
    const language = store.getters['app/language'];
    if (response.status === 401) {
        if (response.url.endsWith('/login')) {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: 'Error',
                message: 'Invalid username or password.',
                buttons: ['OK'],
            });
        } else {
            await presentNativeAlert({
                cssClass: 'custom-alert',
                message: language.errors.sessionExpired,
                buttons: [language.ok.toUpperCase()],
            });
            await store.dispatch('auth/logout');
            await router.push({ name: 'login'});
        }
        throw('');
    } else if (response.status === 403) {
        if (response.url.endsWith('/login')) {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: 'Account Locked',
                message: 'Your account has been locked.<br /><br />Please contact your administrator for assistance.',
                buttons: ['OK'],
            });
            throw('');
        }
    } else if (response.status === 429) {
        return;
    }

    const text = await response.text();
    try{
        const json = JSON.parse(text);
        if (json.detail) {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: language.error,
                message: Object.prototype.hasOwnProperty.call(language.errors, json.detail) ? language.errors[json.detail] : json.detail,
                buttons: [language.ok.toUpperCase()],
            });
        }
    } catch {
        throw(language.error);
    }

    throw('');
}

export async function POST(url: string, data = {}) {
    const fetchurl = process.env.VUE_APP_BASE_URL + url;
    const response = await fetch(fetchurl, {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(data)
    });

    if (response.status === 201 || response.status === 204) {
        return;
    } else if (response.status === 200) {
        return response.json();
    }
    await handleBadResponse(response);
}

export async function FILEUPLOAD(url: string, data: any) {
    const fetchurl = process.env.VUE_APP_BASE_URL + url;
    const isFileUpload = true;
    const response = await fetch(fetchurl, {
        method: 'POST',
        headers: headers(isFileUpload),
        body: data
    });
    
    if (response.status === 201 || response.status === 204) {
        return;
    } else if (response.status === 200) {
        return response.json();
    }
    await handleBadResponse(response);
}

export async function PUT(url: string, data = {}) {
    const fetchurl = process.env.VUE_APP_BASE_URL + url;
    const response = await fetch(fetchurl, {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(data)
    });

    if (response.status === 204) {
        return response;
    } else if (response.status === 200) {
        return response.json();
    }
    await handleBadResponse(response);
}

export async function GET(url: string, blobResponse = false) {
    const fetchurl = process.env.VUE_APP_BASE_URL + url;

    const response = await fetch(fetchurl, {
        method: 'GET',
        headers: headers(),
    });
    
    if (response.status === 200) {
        if (blobResponse) {
            const blob = await response.blob();
            return { blob, headers: response.headers };
        }
        return response.json();
    }
    await handleBadResponse(response);
}

export async function DELETE(url: string) {
    const fetchurl = process.env.VUE_APP_BASE_URL + url;

    const response = await fetch(fetchurl, {
        method: 'DELETE',
        headers: headers(),
    });
    
    if (response.status === 204) {
        return response;
    }
    await handleBadResponse(response);
}
