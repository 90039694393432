import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import mitt from 'mitt';
import '@toast-ui/editor/dist/toastui-editor.css';

import { IonicVue } from '@ionic/vue';

import VueMarkdownIt from 'vue3-markdown-it';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';

import '@ionic/vue/css/padding.css';
import { store } from '@/store/Store';

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(store);

app.use(VueMarkdownIt);
app.config.globalProperties.emitter = mitt();

app.directive('focus', {
    // When the bound element is inserted into the DOM...
    mounted(el) {
        // Focus the element
        el.focus();
    }
});

router.isReady().then(async () => {
    app.mount('#app');
});