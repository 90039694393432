import { createRouter, createWebHistory } from '@ionic/vue-router';
import {RouteLocationNormalized, RouteRecordRaw} from 'vue-router';
import { store } from "@/store/Store";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: () => import ('../views/Auth/Login.vue'),
        props: (route) => ({ token: route.query.token }),
        meta: { public: true }
    },
    {
        path: '/',
        name: 'home',
        component: () => import ('../views/Home.vue')
    },
    {
        path: '/journey-set/create/instructions',
        name: 'journeySetInstructions',
        component: () => import ('../views/JourneySets/CreateJourneySetInstructions.vue'),
        meta: { goBackTo: 'home', permission: 'CreateJourneySet', checkSuspended: true }
    },
    {
        path: '/journey-set/:journeySetId/template',
        name: 'journeySetTemplate',
        component: () => import ('../views/JourneySets/SelectJourneySetTemplate.vue'),
        meta: { goBackTo: 'home', permission: 'CreateJourneySet', checkSuspended: true },
        props: true
    },
    {
        path: '/journey-set/:journeySetId/:templateId?/category',
        name: 'journeySetCategory',
        component: () => import ('../views/JourneySets/SelectJourneySetCategory.vue'),
        meta: { goBackTo: 'home', permission: 'CreateJourneySet', checkSuspended: true },
        props: true
    },
    {
        path: '/journey-set/:journeySetId/:templateId?/:categoryId?/info',
        name: 'journeySetInfo',
        component: () => import ('../views/JourneySets/SetJourneySetInfo.vue'),
        meta: { goBackTo: 'home', permission: 'CreateJourneySet', checkSuspended: true },
        props: true
    },
    {
        path: '/journey-set/:journeySetId/journeys',
        name: 'journeySetJourneys',
        component: () => import ('../views/JourneySets/JourneySetJourneys.vue'),
        meta: {goBackTo: 'journeySetGroups', permission: 'CreateJourneySet', checkSuspended: true},
        props: true
    },
    {
        path: '/journey-set/:journeySetId/journey/:journeyId?/title',
        name: 'journeyTitle',
        component: () => import ('../views/Journeys/JourneyTitle.vue'),
        meta: { goBackTo: 'journeySetJourneys', permission: 'CreateJourneySet', checkSuspended: true },
        props: true
    },
    {
        path: '/journey-set/:journeySetId/journey/:journeyId?/copyright-warning',
        name: 'journeyCopyrightWarning',
        component: () => import ('../views/Journeys/CopyrightWarning.vue'),
        meta: { goBackTo: 'journeySetJourneys', permission: 'CreateJourneySet', checkSuspended: true },
        props: true
    },
    {
        path: '/journey-set/:journeySetId/journey/:journeyId/intro-content',
        name: 'introContent',
        component: () => import ('../views/Journeys/IntroContent.vue'),
        meta: { goBackTo: 'journeySetJourneys', permission: 'CreateJourneySet', checkSuspended: true },
        props: true
    },
    {
        path: '/journey-set/:journeySetId/journey/:journeyId/scripture-content',
        name: 'scriptureContent',
        component: () => import ('../views/Journeys/ScriptureContent.vue'),
        meta: { goBackTo: 'journeySetJourneys', permission: 'CreateJourneySet', checkSuspended: true },
        props: true
    },
    {
        path: '/journey-set/:journeySetId/journey/:journeyId/extra-content',
        name: 'extraContent',
        component: () => import ('../views/Journeys/ExtraContent.vue'),
        meta: { goBackTo: 'journeySetJourneys', permission: 'CreateJourneySet', checkSuspended: true },
        props: true
    },
    {
        path: '/journey-set/:journeySetId/journey/:journeyId/final-content',
        name: 'finalContent',
        component: () => import ('../views/Journeys/FinalContent.vue'),
        meta: { goBackTo: 'journeySetJourneys', permission: 'CreateJourneySet', checkSuspended: true },
        props: true
    },
    {
        path: '/journey-sets',
        name: 'journeySetGroups',
        component: () => import ('../views/JourneySets/JourneySetGroups.vue'),
        meta: { goBackTo: 'home', permission: 'ViewJourneySets' }
    },
    {
        path: '/journey-sets/published',
        name: 'publishedJourneySetGroups',
        component: () => import ('../views/JourneySets/JourneySetGroups.vue'),
        props: true,
        meta: { goBackTo: 'home', permission: 'ManagePublished' }
    },
    {
        path: '/journey-sets/:typeId',
        name: 'journeySets',
        component: () => import ('../views/JourneySets/JourneySets.vue'),
        meta: { goBackTo: 'journeySetGroups', permission: 'ViewJourneySets' },
        props: true
    },
    {
        path: '/journey-sets/published/:typeId',
        name: 'publishedJourneySets',
        component: () => import ('../views/JourneySets/JourneySets.vue'),
        meta: { goBackTo: 'publishedJourneySetGroups', permission: 'ManagePublished' },
        props: true
    },
    {
        path: '/template/instructions',
        name: 'templateInstructions',
        component: () => import ('../views/Template/CreateTemplateInstructions.vue'),
        meta: { goBackTo: 'home', permission: 'CreateJourneyTemplate', checkSuspended: true }
    },
    {
        path: '/template/:id?/name',
        name: 'templateName',
        component: () => import ('../views/Template/TemplateName.vue'),
        meta: { goBackTo: 'templates', permission: 'CreateJourneyTemplate', checkSuspended: true }
    },
    {
        path: '/template/:id/tab-titles',
        name: 'tabTitles',
        component: () => import ('../views/Template/TabTitles.vue'),
        meta: { goBackTo: 'templates', permission: 'CreateJourneyTemplate', checkSuspended: true }
    },
    {
        path: '/template/:id/tabs/:tabId',
        name: 'tab',
        component: () => import ('../views/Template/TabDetails.vue'),
        meta: { goBackTo: 'templates', permission: 'CreateJourneyTemplate', checkSuspended: true }
    },
    {
        path: '/templates',
        name: 'templates',
        component: () => import ('../views/Template/Templates.vue'),
        meta: { goBackTo: 'home', permission: 'ViewJourneyTemplates' }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import ('../views/Users/Users.vue'),
        meta: { goBackTo: 'home', permission: 'ManageUsers' }
    },
    {
        path: '/users/add',
        name: 'addUser',
        component: () => import ('../views/Users/EditUser.vue'),
        meta: { permission: 'ManageUsers' }
    },
    {
        path: '/users/:id/edit',
        name: 'editUser',
        component: () => import ('../views/Users/EditUser.vue'),
        meta: { permission: 'ManageUsers' }
    },
    {
        path: '/reset-password',
        name: 'resetPassword',
        component: () => import('../views/Auth/ChangePassword.vue'),
        props: (route) => ({ token: route.query.token }),
        meta: { public: true }
    },
    {
        path: '/activate',
        name: 'activate',
        component: () => import('../views/Auth/ChangePassword.vue'),
        props: (route) => ({ token: route.query.token }),
        meta: { public: true }
    },
    {
        path: '/app-instances',
        name: 'appInstances',
        component: () => import ('../views/AppInstances/AppInstances.vue'),
        meta: { goBackTo: 'home', permission: 'ManageAppInstances' }
    },
    {
        path: '/app-instances/add',
        name: 'addAppInstance',
        component: () => import ('../views/AppInstances/EditAppInstance.vue'),
        props: true,
        meta: { permission: 'ManageAppInstances' }
    },
    {
        path: '/app-instances/:id/edit',
        name: 'editAppInstance',
        component: () => import ('../views/AppInstances/EditAppInstance.vue'),
        props: true,
        meta: { permission: 'ManageAppInstances' }
    },
    {
        path: '/image-groups',
        name: 'imageGroups',
        component: () => import ('../views/Images/ImageGroups.vue'),
        meta: { goBackTo: 'home', permission: 'ManageImages' }
    },
    {
        path: '/image-groups/:id',
        name: 'imageGroupDetails',
        component: () => import ('../views/Images/ImageGroupDetails.vue'),
        meta: { permission: 'ManageImages' }
    },
    {
        path: '/image-groups/:imageGroupTypeId/:imageGroupId',
        name: 'images',
        component: () => import ('../views/Images/Images.vue'),
        props: true,
        meta: { permission: 'ManageImages' }
    },
    {
        path: '/image-groups/:imageGroupTypeId/:imageGroupId/:imageId',
        name: 'image',
        component: () => import ('../views/Images/Image.vue'),
        props: true,
        meta: { permission: 'ManageImages' }
    },
    {
        path: '/my-account',
        name: 'myAccount',
        component: () => import ('../views/MyAccount/MyAccount.vue'),
        meta: { goBackTo: 'home' }
    },
    {
        path: '/my-account/change-password',
        name: 'changeCurrentPassword',
        component: () => import('../views/Auth/ChangePassword.vue')
    }, 
    {
        path: '/languages',
        name: 'languages',
        component: () => import('../views/Languages/Languages.vue'),
        meta: { goBackTo: 'home', permission: 'ManageLanguages' }
    },
    {
        path: '/languages/add',
        name: 'addLanguage',
        component: () => import('../views/Languages/Language.vue'),
        props: true,
        meta: { permission: 'ManageLanguages' }
    },
    {
        path: '/languages/:id/edit',
        name: 'editLanguage',
        component: () => import('../views/Languages/Language.vue'),
        props: true,
        meta: { permission: 'ManageLanguages' }
    },
    {
        path: '/partners',
        name: 'partners',
        component: () => import('../views/Partners/Partners.vue'),
        meta: { goBackTo: 'home', permission: 'ManagePartners' }
    },
    {
        path: '/partners/add',
        name: 'addPartner',
        component: () => import('../views/Partners/Partner.vue'),
        props: true,
        meta: { permission: 'ManagePartners' }
    },
    {
        path: '/partners/:id/edit',
        name: 'editPartner',
        component: () => import('../views/Partners/Partner.vue'),
        props: true,
        meta: { permission: 'ManagePartners' }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

const hasPermission = (to: RouteLocationNormalized) => {
    return !to.meta.permission || store.getters['auth/loggedInUser'].permissions.includes(to.meta.permission);
}

const isSuspended = (to: RouteLocationNormalized) => {
    return to.meta.checkSuspended && store.getters['app/getSuspendedState'];
}
/*
* An invalid route (name doesn't exist) would be someone manually entering a route that doesn't match 
* any of our predefined routes, like /abcd
* Doing that would show a blank page with the standard app header bar. 
* I thought it would be nice to direct them to a completely valid page instead of a weird half valid kind of page.
*/
const isValidRoute = (to: RouteLocationNormalized) => {
    return to.name;
}

const isLoggedIn = () => {
    return store.getters['auth/token'] && store.getters['auth/token'].length > 10;
}

const isPublic = (to: RouteLocationNormalized) => {
    return to.matched.some((record) => record.meta.public )
}

router.beforeEach((to, from, next) => {
    if (isPublic(to) || (isLoggedIn() && isValidRoute(to) && hasPermission(to) && !isSuspended(to))) {
        next();
    } else if (isLoggedIn()) {
        next({ name: 'home' });
    } else {
        next({ name: 'login' });
    }
});


export default router