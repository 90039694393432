import { GET } from './HTTP';
import { AppInstance } from "@/models/AppInstanceModel";
import {Book} from "@/models/BookModel";

// This query returns a smaller, bandwidth-lighter, result set. Use in scenarios that need less data and faster results.
export async function getAllAppInstances(): Promise<AppInstance[]> {
    return GET('/instances?status=all');
}

// This query returns a larger, bandwidth-heavy, result set. Use in scenarios that can handle more patience.
export async function getAllAppInstancesWithDetails(): Promise<AppInstance[]> {
    return GET('/instances/details?status=all');
}

export async function getCompleteAppInstances(): Promise<AppInstance[]> {
    return GET('/instances?status=complete');
}

export async function getAppInstance(appInstanceId: string): Promise<AppInstance> {
    return GET('/instances/' + appInstanceId);
}

export async function getAppInstanceScripture(appInstanceId: string): Promise<Book[]> {
    return GET(`/instances/${appInstanceId}/scripture`);
}