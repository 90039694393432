import {GET, POST, PUT} from './HTTP';
import {User} from "@/models/UserModel";

export function userLogout() {
    return POST('/logout');
}

export async function resetPassword(token: string, password: string): Promise<any> {
    return POST('/reset-password', {token, password});
}

export async function updatePassword(password: string): Promise<any> {
    return POST('/update-password', { password });
}

export async function isTokenValid(token: string): Promise<boolean> {
    return GET('/reset-password/' + token);
}

export async function isActivationValid(token: string): Promise<boolean> {
    return GET('/users/activation/' + token);
}

export async function activateUser(token: string, password: string): Promise<any> {
    return POST('/users/activation', {token, password });
}

export async function saveUser(user: User): Promise<any> {    
    //create a copy so we don't affect the visual on the screen
    const userWithIds = Object.assign({}, user);
    //set user.appInstanceIds so we can remove appInstances due to payload being too big
    userWithIds.appInstanceIds = userWithIds.appInstances.map(instance => instance.id);
    userWithIds.appInstances = [];
    return PUT('/users', userWithIds);
}

export async function getUsers(status: string, searchTerm: string): Promise<User[]> {
    return GET(`/users?status=${status}&search=${searchTerm}`);
}