import { AlertOptions } from '@ionic/core';
import { alertController } from '@ionic/vue';
import {store} from "@/store/Store";

export async function presentNativeAlert(options: AlertOptions) {
    options.mode = "md";
    const alert = await alertController.create(options);
    await alert.present();
}

export async function presentValidationAlert(message: string) {
    const language = store.getters['app/language'];
    await presentNativeAlert({
        cssClass: 'custom-alert',
        header: '',
        message: message,
        buttons: [ Object.keys(language).length > 0 ? language.ok.toUpperCase() : 'OK'],
    });
}