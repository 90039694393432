
import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonMenuButton,
    menuController, 
    IonToolbar, 
    IonButtons,
    IonHeader,
    IonTitle
} from '@ionic/vue';
import { ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
    homeSharp,
    languageOutline,
    globeOutline,
    phonePortraitSharp,
    contractOutline,
    personSharp,
    peopleSharp,
    imagesOutline,
    closeSharp,
    logOutOutline
} from 'ionicons/icons';
import {useStore} from "vuex";
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default {
    name: 'BaseLayout',
    props: {
        pageTitle: {
            type: String,
            default: ""
        }  
    },
    components: {
        DirectionIndicator,
        IonContent,
        IonIcon,
        IonItem,
        IonLabel,
        IonList,
        IonMenu,
        IonMenuToggle,
        IonMenuButton,
        IonToolbar,
        IonButtons,
        IonHeader,
        IonTitle
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const selectedIndex = ref(0);
        const language = computed(() => store.getters['app/language']);
        const dynamicSide = computed(() => language.value.direction === 'rtl' ? 'start' : 'end');
        const isMenuOpen = ref(false);
        const showBackIcon = ref(route.name !== 'home');
        const currentUser = ref(store.getters['auth/loggedInUser']);
        
        watch(route, (currentRoute) => {
            showBackIcon.value = currentRoute.name !== 'home';
        });
       
        function toggleMenu(value: boolean) {
            isMenuOpen.value = value;
        }

        async function goBack() {
            await menuController.close();
            if (router.currentRoute.value.meta.goBackTo) {
                await router.replace({ name: router.currentRoute.value.meta.goBackTo as string, params: router.currentRoute.value.params });
            } else {
                await router.back();
            }
        }
        
        function goToAppInstances() {
            router.push({name: 'appInstances'});
        }

        function goToUsers() {
            router.push({name: 'users'});
        }

        function goToPartners() {
            router.push({name: 'partners'});
        }
        
        function goToPublished() {
            router.push({ name: 'publishedJourneySetGroups' });
        }
        
        function goToImages() {
            router.push({ name: 'imageGroups'});
        }

        function goToLanguages() {
            router.push({ name: 'languages'});
        }

        async function goHome() {
            await menuController.close();
            router.push({ name: 'home'});
        }
        
        function goToMyAccount() {
            router.push({ name: 'myAccount' });
        }
        
        async function logout() {
            await menuController.close();
            await store.dispatch('auth/logout');
            router.push({ name: 'login'});
        }
        
        return {
            selectedIndex,
            globeOutline,
            homeSharp,
            phonePortraitSharp,
            contractOutline,
            personSharp,
            peopleSharp,
            imagesOutline,
            languageOutline,
            logOutOutline,
            closeSharp,
            dynamicSide,
            toggleMenu,
            isMenuOpen,
            goBack,
            goToAppInstances,
            goToUsers,
            goToImages,
            goHome,
            goToMyAccount,
            goToPublished,
            goToLanguages,
            goToPartners,
            showBackIcon,
            logout,
            language: computed(() => store.getters['app/language']),
            canManagePublished: computed(() => currentUser.value.permissions?.includes("ManagePublished")),
            canManageUsers: computed(() => currentUser.value.permissions?.includes("ManageUsers")),
            canManageAppInstances: computed(() => currentUser.value.permissions?.includes("ManageAppInstances")),
            canManageImages: computed(() => currentUser.value.permissions?.includes("ManageImages")),
            canManageLanguages: computed(() => currentUser.value.permissions?.includes("ManageLanguages")),
            canManagePartners: computed(() => currentUser.value.permissions?.includes("ManagePartners")),
            notHome: computed(() => router.currentRoute.value.name !== 'home')
        }
    }
}
